/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import IndexHeader from "./indexheader"
import Footer from "./footer"
import "./layout.css"
import pointerImg from '../../static/cursor.png'
import Share from "./share"

const Overflow = styled.div`
  overflow-x: hidden;
  ::selection {
    background: #6594d4; /* WebKit/Blink Browsers */
  }
`

function Cursor({ x, y }) {
  return (
    <img
      key="cursor"
      alt="pointer"
      style={{
        top: y - 150,
        left: x - 150,
        position: 'fixed',
        width: '300px',
        height: '300px',
        pointerEvents: 'none',
        mixBlendMode:'screen',
        zIndex: 12,
        background:'none'
      }}
      src={pointerImg}
    />
  )
}
class IndexLayout extends React.Component {
	constructor(props) {
    super(props)
    const count = 1
    this.state = {
      mouse: [0, 0],
      cursor: [0, 0]
    }
  }
  _onMouseMove = e => {
    this.setState({ mouse: [e.x, e.y] })
  };
  componentDidMount() {
    window.onmousemove = this._onMouseMove
    window.onclick = this._onClick

  }




	render() {
		return (
			<Overflow>
				<IndexHeader location={this.props.location} />
        <div className="cursor">
          
          {/* <Cursor x={this.state.mouse[0]} y={this.state.mouse[1]} />
           */}
					<div
						style={{
							position: "absolute",
							top: 0,
							margin: "0 auto",
							overflow: "hidden",
							padding: "0",
              paddingTop: 0,
              width: "100%",
						}}
					>
						<main>{this.props.children}</main>
            <Share
              socialConfig={{
                twitterHandle: "@GLOBART_Academy",
                config: {
                  url: `${"https://www.globart-academy.at"}${this.props.location}`,
                  title: "GLOBART Academy",
                },
              }}
              tags="GLOBART Academy 2020 MACHT"
            />
						<Footer />
					</div>
        </div>


			</Overflow>
		)
	}
}

IndexLayout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default IndexLayout
