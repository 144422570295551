import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import IndexMenu from "./indexmenu"
import { StaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs';

const LogoContainer = styled.h1`
  margin: 0;
  background: white;
  @media only screen and (max-width: 1180px) {
    display: flex;
    justify-content: center;
  }
`
const Logo = styled.img`
  width: 250px;
  background:none;
  @media only screen and (max-width: 1180px) {
    width: 200px;
    margin-bottom: -15px;
  }
`
const StyledHeader = styled.header`
  position: ${props => props.position};
  top: ${props => (props.position ? props.top + "px" : "0")};
  display: flex;
  width: 100%;
  max-width: ${props => (props.index ? "auto" : "auto")};
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;
  left: 0;
  right: 0;
  z-index: 14;
  max-height: 6.5rem;
  background: white;
  border-bottom: 2px solid black;
  @media only screen and (max-width: 1080px) {
    top: ${props => (props.down ? "0" : props.topmobile + "px")};
    justify-content: center;
    flex-direction: column;
    max-height: none;
    transform: translateY(-3rem);
    margin: 0;
    padding-bottom: 0;
  }
  @media only screen and (max-width: 350px) {
    width: auto;
  }
`
class IndexHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scroll: 0,
      top: false,
      windowHeight: 0,
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.onresize)
    this.setState({
      windowHeight: window.innerHeight,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("resize", this.onresize)
  }
  onresize = () => {
    this.setState({
      windowHeight: window.innerHeight,
    })
  }
  handleScroll = () => {
    this.setState({
      scroll: window.scrollY,
      windowHeight: window.innerHeight,
    })
    if (this.state.scroll >= window.innerHeight) {
      this.setState({
        down: true,
      })
    } else {
      this.setState({
        down: false,
      })
    }
  }
  render() {
    const { index } = this.props
    const data = this.props.data.prismic.allHomepages.edges[0].node
    return (
      <StyledHeader
        index={index}
        position={this.state.down ? "fixed" : "absolute"}
        top={this.state.down ? 0 : this.state.windowHeight}
        topmobile={this.state.down ? 48 : this.state.windowHeight + 30}
      >
        <LogoContainer>
          <Link to="/">
            <Logo
              src={data.logo.url}
              alt={data.title[0].text}
            />
          </Link>
        </LogoContainer>
        <IndexMenu location={this.props.location} />
      </StyledHeader>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query IndexHeadingQuery {
             prismic {
          allHomepages {
            edges {
              node {
                logo
                title
              }
            }
          }
        }
      }
    `}
    render={data => <IndexHeader data={data} {...props} />}
  />
)
