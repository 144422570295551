import React from "react"

import Tilt from "react-tilt"
import ReactSwipe from "react-swipe"
import styled from "styled-components"

const StyledSlider = styled(ReactSwipe)`
  position: absolute;
  width: 100vw;
`
const SliderContainer = styled.div`
  width: 100vw;
`
const SliderContainerInner = styled.div`
  transform: translateZ(-400px);
`
const SliderPrev = styled.div`
  position: absolute;
  top: calc(200px + 10vw);
  height: 1em;
  width: 100px;
  font-size: 2em;
  z-index: 3;
  text-align: center;
  cursor: pointer;
`
const SliderNext = styled(SliderPrev)`
  right: 0;
`
const SliderImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const SliderImg = styled.img`
  max-height: 700px;
  width: 100%;
  object-fit: contain;
  transform: translateY(-20px);
`
const SliderImgBottles = styled(SliderImg)`
  transform: translateY(-20px);
`

const SliderImgFlamingo = styled(SliderImg)`
  transform: translateX(80px) translateY(-60px) rotate(20deg);
  @media only screen and (max-width: 800px) {
    transform: translateY(-20px) rotate(20deg);
  }
`
const SliderImgKanne = styled(SliderImg)`
  transform: translateX(80px) translateY(-20px);
  @media only screen and (max-width: 800px) {
    transform: translateY(-20px);
  }
`
const Red = styled.img`
  opacity: 0;

  :hover {
    opacity: 1;
    animation: blink 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    45% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`
class Slider extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.swipe(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }
  swipe() {}
  render() {
    let reactSwipeEl
    if (this.props.location.startsWith("/en/")) {
      var locale = "/en/"
    } else {
      var locale = ""
    }

    return (
      <div
        className="Tilt"
        options={{
          max: 0,
          scale: 1, // 2 = 200%, 1.5 = 150%, etc..
          transition: true, // Set a transition on enter/exit.
          easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        }}
        style={{
          height: "calc(100vh)",
          width: "100vw",
        }}
      >
        <img
          alt="globart"
          src={
            locale == "/en/"
              ? "../../globart-logo-red.png"
              : "../globart-logo-red.png"
          }
          style={{
            position: "absolute",
            maxWidth: "1000px",
            width: "90vw",
            top: "22vh",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            zIndex: 14,
          }}
        />
        <Red
          alt="globart"
          src={
            locale == "/en/"
              ? "../../globart-academy-logo-red.png"
              : "../globart-academy-logo-red.png"
          }
          style={{
            position: "absolute",
            maxWidth: "1000px",
            width: "90vw",
            top: "22vh",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            zIndex: 14,
          }}
        />
        <SliderContainer>
          <SliderContainerInner>
            <StyledSlider
              className="carousel"
              swipeOptions={{
                continuous: true,
                auto: 2000,
              }}
              ref={el => (reactSwipeEl = el)}
            >
              {/* <SliderImgContainer>
                <SliderImgBottles alt="globart" src={"../bottles.png"} />
              </SliderImgContainer>
              <SliderImgContainer>
                <SliderImgKanne alt="globart" src={"../stein.png"} />
              </SliderImgContainer>
              <SliderImgContainer>
                <SliderImgFlamingo alt="globart" src={"../kind.png"} />
              </SliderImgContainer>  */}
            </StyledSlider>
          </SliderContainerInner>
        </SliderContainer>
      </div>
    )
  }
}

export default Slider
