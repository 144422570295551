import React from "react"
import { Link, graphql } from "gatsby"
import IndexLayout from "../components/indexlayout"
import SEO from "../components/seo"
import Slider from "../components/slider"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import Swiper from "../components/swiper"
import { FaYoutube, FaFlickr } from "react-icons/fa"
import { IconContext } from "react-icons"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Content = styled.div`
  max-width: 1200px;
  margin: 3rem auto 0 auto;
  scroll-behavior: smooth;
  padding-top: 8rem;
  p {
    word-break: normal;
    hyphens: auto;
  }
`
const Heading = styled.h1`
  padding: 0 3rem 3rem 3rem;
  font-size: 2.05rem;
  width: 100%;
  font-family: "Marr Sans Web";
  font-style: italic;
`
const StyledSwiper = styled(Swiper)`
  padding-bottom: 3rem;
`

const Content2 = styled(Content)`
  margin: 0 auto 0 auto;
  padding-top: 4rem;
`
const Banner = styled.div`
  background: red;
  > div {
    max-width: 1200px;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    margin: 0 auto;
    gap: 1rem;
  }
  > div > div {
    padding-left: 3rem;
  }
  p {
    font-size: 1.8em;
    line-height: 1em;
  }
  a {
    display: block;
    font-size: 1.8em;
    line-height: 1.8em;
    color: black;
    text-decoration: none;
  }

  @media only screen and (max-width: 1080px) {
    > div {
      grid-template-columns: 3fr 2fr;
    }
    > div > div {
      padding-left: 0;
    }
    p {
      font-size: 1.4em;
    }
    a {
      display: block;

      font-size: 1.4em;
      line-height: 1.8em;
      color: black;
      text-decoration: none;
    }
  }
  @media only screen and (max-width: 700px) {
    > div {
      grid-template-columns: 1fr;
      padding-bottom: 2rem;
    }
  }
`
const Teaser = styled.div`
  padding: 1rem;
  color: ${props => (props.red ? "red" : "black")};
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  h1:last-of-type {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0;
  }
`
const FakeLink = styled.span`
  padding: 1rem;
  color: ${props => (props.red ? "red" : "black")};
  :hover {
    opacity: 0.8;
  }
`
const TeaserContainer = styled.div`
  display: grid;
  grid-gap: 3em;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 2rem;
  margin: 3rem 0 10rem 0;
  a {
    text-decoration: none;
  }
  a:nth-child(1) {
    transform: translateY(100px);
  }
  a:nth-child(2) {
    transform: translateY(-50px);
  }
  a:nth-child(3) {
    transform: translateY(100px);
  }
  @media only screen and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
    a:nth-child(1) {
      transform: translateY(100px);
    }
    a:nth-child(2) {
      transform: translateY(0);
    }
    a:nth-child(3) {
      transform: translateY(40px) translateX(50%);
    }
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 3em 0 3em 0;

    a:nth-child(1) {
      transform: translateY(0);
    }
    a:nth-child(2) {
      transform: translateY(0);
    }
    a:nth-child(3) {
      transform: translateX(0);
    }
  }
`

const Academy = styled.div`
  div {
    padding: 0 1rem;
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "heading1 heading2"
      "content1 content2";
  }
  h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  p {
  }
  h1:nth-child(1) {
    grid-area: heading1;
  }
  p:nth-child(2) {
    grid-area: content1;
  }
  h1:nth-child(3) {
    grid-area: heading2;
  }
  p:nth-child(4) {
    grid-area: content2;
    margin-top: 0;
  }
  a {
    color: black;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
  }
  a:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 800px) {
    div {
      grid-template-columns: 1fr;

      grid-template-areas:
        "heading1"
        "content1"
        "heading2"
        "content2";
    }
    h1 {
      width: 100%;
    }
    p {
      font-size: 1rem;

      column-count: 1;
      width: 100%;
    }
  }
`
const Html = styled.div`
  padding: 5em 1em 4em 1em;
  p {
    line-height: 1.2em;
    font-size: 2em;
  }
  strong {
    font-weight: normal;
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      gedankenexperimentTeaser,
      akwTeaser,
      parlamentTeaser,
      banner,
      academy,
      speakers,
      triade,
      slider,
    } = this.props.data.prismic.allHomepage2020s.edges[0].node
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }

    return (
      <IndexLayout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY 2020"
          description="Die GLOBART Academy Triade LEBEN-MACHT-SINN ist zurück! Die letzten Monate sind eine internationale Bestätigung: nun ist es Zeit über MACHT zu reden!"
          keywords={[
            "GLOBART Academy",
            "AKW Zwentendorf",
            "Macht",
            "Philosophie",
          ]}
        />
        <Slider location={this.props.location.pathname} />
        <Content id="top">
          <TeaserContainer>
            <Link to="/gedankenexperiment">
              <Teaser> {RichText.render(gedankenexperimentTeaser)} </Teaser>{" "}
              <FakeLink>
                <strong> Mehr zur Academy im Kopf→ </strong>{" "}
              </FakeLink>{" "}
            </Link>{" "}
            <Link to="/akw">
              <Teaser> {RichText.render(akwTeaser)} </Teaser>{" "}
              <FakeLink>
                <strong> Mehr zur Academy im Reaktor→ </strong>{" "}
              </FakeLink>{" "}
            </Link>{" "}
            <Link to="/">
              <Teaser> {RichText.render(parlamentTeaser)} </Teaser>{" "}
              <FakeLink>
                <strong> Mehr zur Academy im Parlament→ </strong>{" "}
              </FakeLink>{" "}
            </Link>{" "}
          </TeaserContainer>{" "}
          <Heading> GLOBART Academy 2020 im AKW – Rückblick </Heading>{" "}
          <StyledSwiper slider={slider} />{" "}
          <iframe
            width="100%"
            height=""
            style={{
              padding: "2rem 3rem",
              height: "58vw",
              maxHeight: "680px",
            }}
            src="https://www.youtube.com/embed/videoseries?list=PLRSHqgD1wKLJK8P695CNvB3NbASMTqwdX"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>{" "}
          <IconContext.Provider
            value={{
              color: "#0063DC",
              size: "3em",
            }}
          >
            <div
              style={{
                padding: "0 3rem 0 3rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p>
                <a
                  href="https://flic.kr/s/aHsmSVAjho"
                  target="_blank"
                  style={{
                    color: "#000",
                    fontSize: "1.23rem",
                    paddingRight: "2rem",
                  }}
                >
                  Alle Fotos der GLOBART Academy 2020 auf Flickr{" "}
                </a>{" "}
              </p>{" "}
              <a
                href="https://flic.kr/s/aHsmSVAjho"
                target="_blank"
                style={{
                  transform: "translateY(-0.5rem)",
                }}
              >
                <FaFlickr />
              </a>{" "}
            </div>{" "}
          </IconContext.Provider>{" "}
        </Content>
        <Content2>
          <Html> {RichText.render(triade)} </Html>{" "}
          <Academy id="academy"> {RichText.render(academy)} </Academy>{" "}
        </Content2>{" "}
      </IndexLayout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    prismic {
      allHomepage2020s(lang: "de-at") {
        edges {
          node {
            akwTeaser
            parlamentTeaser
            gedankenexperimentTeaser
            academy
            slider {
              slidebody
              image
            }
            triade
            banner
            speakers {
              speaker {
                ... on PRISMIC_Speaker {
                  title
                  quote
                  description
                  thumbnail
                  _meta {
                    uid
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
