import React from "react"

import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const StyledNav = styled.nav`
  color: black;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    display: flex;
    margin: 0;
  }
  ul li a {
    margin: 0;
    padding: 1rem;
    color: black;
    text-decoration: none;
    font-family: "Marr Sans Cond Web";
    text-transform: uppercase;
  }
  ul li a:hover {
    color: #6594d4;
  }
  label.hamburg {
    cursor: pointer;
    display: none;
    background: #fff;
    width: 30px;
    height: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
  }

  input#hamburg {
    display: none;
  }
  .line {
    position: absolute;
    left: 10px;
    height: 4px;
    width: 30px;
    background: #000;
    border-radius: 2px;
    display: block;
    transition: 0.5s;
    transform-origin: center;
  }

  .line:nth-child(1) {
    top: 12px;
  }
  .line:nth-child(2) {
    top: 24px;
  }
  .line:nth-child(3) {
    top: 36px;
  }

  #hamburg:checked + .hamburg .line:nth-child(1) {
    transform: translateY(12px) rotate(-45deg);
  }

  #hamburg:checked + .hamburg .line:nth-child(2) {
    opacity: 0;
  }

  #hamburg:checked + .hamburg .line:nth-child(3) {
    transform: translateY(-12px) rotate(45deg);
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .rotating {
    animation: rotating 8s linear infinite;
  }
  @media only screen and (max-width: 950px) {
    margin-top: 1rem;
  }
  @media only screen and (max-width: 950px) {
    .rotating {
      display: none;
    }
    justify-content: flex-end;
    margin-top: -1rem;
    margin-bottom: 1rem;

    label.hamburg {
      display: block;
      position: absolute;
      top: 1rem;
      right: 3rem;
    }
    .not-displayed {
      flex-direction: column;
      display: none;
    }
    li {
      margin: 1rem;
      text-align: right;
    }
    .displayed {
      display: block;
    }
  }
`
const Dropdown = styled.li`
  position: relative;
  :hover {
    div {
      border: 2px solid black;
      border-top: none;
    }
    div a {
      display: inline;
      white-space: nowrap;
    }
  }
  div {
    z-index: 7;
    background: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 800px) {
      left: -0.7rem;
    }
  }
  div a {
    display: none;
    padding: 0.5rem;
  }
`
const Language = styled.ul`
  display: flex;
  flex-direction: column;
  transform: translateY(-1rem);
  @media only screen and (max-width: 1000px) {
    transform: translateY(-14px);
    li {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
const Search = styled.li`
  margin-left: -1rem;
`
const Press = styled.div`
  right: 7px;
`
const Highlight = styled(Link)`
  background: #6594d4;
  color: white !important;
  @media only screen and (max-width: 800px) {
    margin-bottom: 1rem;
  }
`
class IndexMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menu: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({
      menu: !this.state.menu,
    })
  }
  render() {
    const location = this.props.location
    const { data } = this.props
    if (location.startsWith("/en/")) {
      var locale = "/en/"
      var en = this.props.location
      var de = this.props.location.slice(4, this.props.location.length)
      var program = "Life 2019"
      var sinn = "Sinn 2021"
      var speaker = "Speakers"
      var macht = "Power 2020"
      var arrival = "arrival"
      var contact = "Contact"
      var press = "Press"
      var timetable = "Timetable"
      var thoughtexperiment = "In the Head"
      var akw = "In the Reactor"
      var parliament = "In the Parliament"
      var tickets = "Tickets"
      var scholarship = "Scholarship"
    } else {
      var lang = this.props.data.de
      var en = "/en" + this.props.location
      var de = ""
      var program = "Leben 2019"
      var sinn = "Sinn 2021"
      var locale = "/"
      var macht = "Macht 2020"
      var arrival = "Anfahrt"
      var press = "Presse"
      var timetable = "Zeitplan"
      var speaker = "ReferentInnen"
      var contact = "Kontakt"
      var thoughtexperiment = "Im Kopf"
      var akw = "Im Reaktor"
      var parliament = "Im Parlament"
      var tickets = "Tickets"
      var scholarship = "Stipendium"
    }

    return (
      <StyledNav>
        <div className="row">
          <input type="checkbox" id="hamburg" onClick={this.handleClick} />
          <label for="hamburg" className="hamburg">
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </label>
        </div>
        <ul
          style={{ position: "relative" }}
          className={this.state.menu ? "displayed" : "not-displayed"}
        >
          <Language>
            <li>
              <Link to={`/${de}`} activeStyle={{ color: "#6594D4" }}>
                DE
              </Link>
            </li>{" "}
            <li>
              <Link to={en} activeStyle={{ color: "#6594D4" }}>
                EN
              </Link>
            </li>
          </Language>
          {/* <li>
						<a href="#arrival">{arrival}</a>
					</li> */}
          <li>
            <Link to={`${locale}sinn`}>{sinn}</Link>
          </li>
          <li>
            <Link to={`${locale}stipendium`}>{scholarship}</Link>
          </li>
          <img
            alt="globart"
            class="rotating"
            src={
              locale == "/en/"
                ? "../../parlament-circle.png"
                : "../parlament-circle.png"
            }
            style={{
              position: "absolute",
              width: "130px",
              right: locale == "/en/" ? "478px" : "497px",
              top: "-50px",
              pointerEvents: "none",
            }}
          />
          <Dropdown>
            <Link to={`${locale}macht`}>{macht}</Link>
            <div>
              <Link to={`${locale}gedankenexperiment`}>
                {thoughtexperiment}
              </Link>
              <Link to={`${locale}akw`}>{akw}</Link>
              <Link to={`${locale}`}>{parliament}</Link>
            </div>
          </Dropdown>

          <Dropdown>
            <Link to={`${locale}rueckblick`}>{program}</Link>
            <div>
              <Link to={`${locale}speaker`}>{speaker}</Link>
            </div>
          </Dropdown>
          {/*
					<Dropdown>
						<Link to={`${this.props.location}rueckblick`}>{program}</Link>
						<div>
				
							{lang.nodes.map(weekend => (
								<Link
									key={weekend.uid}
									to={`${this.props.location}/${weekend.uid}`}
								>
									{weekend.data.title.text}
								</Link>
              ))}
							<Link to={`${this.props.location}rueckblick`}>
								{review}
							</Link>
							<Link to={`${this.props.location}programm-utopien`}>
								{timetable}
							</Link>
							<Link to={`${this.props.location}/formate`}>{formate}</Link>
							<Link to={`${this.props.location}program`}>{speaker}</Link>
						</div>
					</Dropdown>
              */}

          {/*
					<li>
						<Link to={`${this.props.location}/faq`}>FAQ</Link>
					</li> */}
          <li>
            <Link to={`${locale}arrival`}>{arrival}</Link>
          </li>
          <Dropdown>
            <Link to={`${locale}kontakt`}>{contact}</Link>
            <Press> 
              <Link to={`${locale}presse`}>{press}</Link>
            </Press>
          </Dropdown>
          {/*	<Search>
						<Link to={`${this.props.location}search`}>🔍</Link>
					</Search> */}
          <li>
            <Highlight to={`${locale}tickets`}>{tickets}</Highlight>
          </li>
          <li>
            <a href="http://globart.at" target="_blank">
              GLOBART.at
            </a>
          </li>
        </ul>
      </StyledNav>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query IndexMenuQuery {
        prismic {
          allHomepages {
            edges {
              node {
                logo
                title
              }
            }
          }
        }
      }
    `}
    render={data => <IndexMenu data={data} {...props} />}
  />
)
